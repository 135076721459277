import React, { useEffect, useRef } from 'react'
import GithubCalendar from 'github-calendar'
import 'github-calendar/dist/github-calendar.css'
import '../styles/custom-github-calendar.css'

const GithubCalendarWrapper = ({ githubUsername }) => {
  const eleRef = useRef()
  const eleClassName = "github-calendar"
  const options = {
    responsive: true,
    summary_text: '',
    global_stats: false,
  }

  useEffect(() => {
    GithubCalendar(`.${eleClassName}`, githubUsername, options);
  })

  return (
    <div className={eleClassName} ref={eleRef}>Loading...</div>
  )
}

export default GithubCalendarWrapper