import React from "react"
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import GithubCalendarWrapper from "../components/githubCalendarWrapper"
import { StaticImage } from 'gatsby-plugin-image'

const AboutPage = ({ location, ...restProps }) => {
  return (
    <Layout location={location}>
      <Helmet title={`Threefields Media - About`} />
      <div className="grid md:grid-cols-7 gap-8">
        <div className="md:col-span-2">
          <StaticImage className="rounded-full" src="../images/me3.png" alt="Myself" />
          <p className="mt-4 text-center">
            &#128188; current role: CTO @ <a href="https://wearhealth.com" target="_blank" rel="noreferrer">Wearhealth</a>
          </p>
          <GithubCalendarWrapper githubUsername="threefields" />
        </div>
        <div className="md:col-span-5">
          <div>
            <article className="prose text-gray-50 max-w-none">
              <h1 className="text-gray-50">Maverick / Tausendsassa / Πολυμήχανος</h1>
              <h2 className="text-gray-50">Hi visitor!</h2>
              <p>You are probably here because you are curious about the person behind this domain. I'm Stefanos and this is my online residence where you'll find links to my articles.</p>
              <p>I've been in the field of software engineering for almost {new Date().getFullYear() - 2012} years and always on the lookout for new problems to solve.</p>
              <p>As testament to my capabilities:</p>
              <ul>
                <li>I am able to repay technical debt faster than it can accumulate</li>
                <li>Vertically centering a div is something I can do without Googling the solution</li>
                <li>I can manage teams that are larger than two pizzas can feed</li>
              </ul>
              <small><b>Disclaimer:</b> some of the statements above are sarcastic.</small>
              <p>Feel free to reach out to me via any of your preferred channels (links in the footer below).</p>
              <details>
                <summary>Public key</summary>
                <textarea className="public-key-area" defaultValue="AAAAB3NzaC1yc2EAAAADAQABAAABAQDSn0UMjfMn/o/KZYMhJHCF8lkGWeM2TDsqBHFKzb4B2U1e3x0gF0aEvmm5t7B5Mkl90FkuITz9Uul5Gc8hDwknml4Hu1r5GFLuFSqpkA593RIeP4fuQtgNBI6gtNryRDr0hGTQuiMKLmq4BN3znWq9WU2wl36O8qUCK7MpbwgSLmAgm3XwSNF6rxjJSVN402dtIH6VaM/1h622nM0/vvZLr+Km6GTWA5HlF3reAUMKN3uSpIcwyY5OecMOZafaCnzMuceeoZ+m2g9WWMROxCc1QQl00ZJxFb3/l9YBTSrDqIBB2P/q9GADdsgV9yqr1TGu33YPTLM1GtxG46gVbQiH" disabled />
              </details>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
